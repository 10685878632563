/* To center the spinner*/
.posCenter {
  position: fixed;
  top: calc(50% - 33px);
  left: calc(50% - 33px);
  height: calc(100vh - 23px);
  width: calc(100vw - 23px);
}

.loader {
  width: 66px;
  height: 66px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
